import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";

export default function VisaApplicationStatus() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    comment: "",
    status: "",
    comment_name: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  function table() {
    axios
      .get(
        "http://13.232.52.101/api/visa_applicationstatus/getallvisa_applicationstatus",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setVisaList(response.data.Visa_Application_Status);
        console.log(response.data.Visa_Application_Status);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        "http://13.232.52.101/api/visa_applicationstatus/createvisa_applicationstatus",
        {
          status_name: state.status,
          is_comment_available: state.comment,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      comment: "",
      status: "",
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/visa_applicationstatus/deletevisa_applicationstatus",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/visa_applicationstatus/updatevisa_applicationstatus",
        {
          id: idx,
          status_name: state.status,
          is_comment_available: state.comment,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      comment: "",
      status: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Visa Application Status
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Application Status</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Status"
                  fullWidth
                  value={state.status}
                  onChange={(e) =>
                    setState({
                      ...state,
                      status: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["True", "false"]}
                  value={state.comment_name}
                  changeCallBack={(e, v) => {
                    console.log(v);
                    if (v == "True") {
                      setState({
                        ...state,
                        comment: 1,
                        comment_name: "True",
                      });
                    } else {
                      setState({
                        ...state,
                        comment: 0,
                        comment_name: "False",
                      });
                    }
                  }}
                  label="Is comment available"
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.status) {
                        handleUpdateVisaCategory(state.id);
                      }
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.status) {
                        handleSaveVisaCategory();
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Application Status List</p>
          <Paper className={classes.paper}>
            {/* <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            /> */}
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Status", "Is Comment Available", "Action"]}
              rows={[
                ...visaList.map((vs, idx) => [
                  idx + 1,
                  vs.status_name,
                  vs.is_comment_available == 1 ? "True" : "False",

                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleVisaCategoryDelete(vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        const c_name =
                          vs.is_comment_available == 1 ? "True" : "False";
                        setState({
                          status: vs.status_name,
                          comment: vs.is_comment_available,
                          comment_name: c_name,
                          isUpdate: true,
                          id: vs.id,
                        });
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
