import { Avatar, Divider, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import PageTable from "../../components/PageTable/Index";
import MenuButton from "../../components/MenuButton/Index";
import { MdLogout } from "react-icons/md";
export default function InfoListPage() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [entrylist, setEntryList] = React.useState([]);
  const [entryMap, setEntryMap] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify({ page: page }),
      };
      const res = await fetch(
        "http://13.232.52.101/api/visainformation/getallvisainformations",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.Visa_Information);
    } catch (err) {
      console.log(err);
    }
  };

  async function entry_map() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/visaentrytypemap/getallvisaentrytypemap",
        requestOptions
      );
      const response = await res.json();
      setEntryMap(response.VisaEntryTypeMap);
      console.log(response.VisaEntryTypeMap);
    } catch (err) {
      console.log(err);
    }
  }
  const getVisaInfoWithType = () => {
    const resultMap = new Map();

    entryMap.forEach((entryGroup) => {
      const visaInfo = list.find((info) => info.id === entryGroup.visa_id);

      if (visaInfo) {
        entryGroup.entry_types.forEach((entry) => {
          const key = JSON.stringify(visaInfo);

          if (!resultMap.has(key)) {
            resultMap.set(key, {
              visaInfo,
              entryTypeNames: [],
              map_ids: [],
              entry_ids: [],
            });
          }

          const resultEntry = resultMap.get(key);
          resultEntry.entryTypeNames.push(entry.entry_type_name);
          resultEntry.map_ids.push(entry.entry_type_id); // Changed from entry.id to entry.entry_type_id
          resultEntry.entry_ids.push(entry.entry_type_id);
        });
      }
    });

    return Array.from(resultMap.values());
  };
  const fetchData = async () => {
    try {
      const tableData = await table();
      const entryMapData = await entry_map();
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors if necessary
    }
  };
  React.useEffect(() => {
    fetchData(); // Call the fetchData function
  }, []);
  React.useEffect(() => {
    const visaInfoWithTypeArray = getVisaInfoWithType();
    setMainList(visaInfoWithTypeArray);
    console.log("combined array");
    console.log(visaInfoWithTypeArray);
  }, [list, entryMap]);
  const handleDelete = async (idx, map) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/visainformation/deletevisainformation",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status === 200) {
        // Loop through each element in the map array
        map.forEach((mapId) => {
          axios
            .post(
              "http://13.232.52.101/api/visaentrytypemap/deletevisaentrytypemap",
              {
                id: mapId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((r) => {
              const fetchData = async () => {
                try {
                  await Promise.all([table(), entry_map()]);
                } catch (error) {
                  console.error("Error fetching data:", error);
                  // Handle errors if necessary
                }
              };
              fetchData();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              // Handle error here
            });
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Visa Information List
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/information_form");
              }}
            >
              Create Visa Information
            </Button>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Visa Name",
              "Visa Thumbnail",
              "Visa Pricing",
              "Creation Date",
              "Receive within days",
              "Description",
              "Validity Period",
              "Entry Type",
              "Action",
            ]}
            rows={[
              ...mainlist
                .sort()
                .filter((l) => {
                  const visaName = l.visaInfo.visa_name?.toLowerCase() || "";
                  const visaPricing =
                    l.visaInfo.visa_pricing?.toLowerCase() || "";
                  const receiveDays =
                    l.visaInfo.receive_days?.toLowerCase() || "";
                  const validityPeriod =
                    l.visaInfo.validity_period?.toLowerCase() || "";
                  const entryTypeName = l.entryTypeName?.toLowerCase() || "";
                  const flightChangeOverDescriptions =
                    JSON.parse(
                      l.visaInfo.flight_change_over_descriptions
                    )?.blocks[0].text?.toLowerCase() || "";

                  const searchLower = searchText.toLowerCase();

                  return (
                    visaName.includes(searchLower) ||
                    visaPricing.includes(searchLower) ||
                    receiveDays.includes(searchLower) ||
                    validityPeriod.includes(searchLower) ||
                    entryTypeName.includes(searchLower) ||
                    flightChangeOverDescriptions.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                .map((vs, idx) => [
                  idx + 1,
                  vs.visaInfo.visa_name,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={vs.visaInfo.visa_thumbnail}
                    style={{
                      margin: "auto",
                    }}
                  />,
                  vs.visaInfo.visa_pricing,
                  vs.visaInfo.created_date.slice(0, 10),
                  vs.visaInfo.receive_days,
                  JSON.parse(vs.visaInfo.flight_change_over_descriptions)
                    .blocks[0].text.length > 34
                    ? JSON.parse(
                        vs.visaInfo.flight_change_over_descriptions
                      ).blocks[0].text.slice(0, 34) + "...."
                    : JSON.parse(vs.visaInfo.flight_change_over_descriptions)
                        .blocks[0].text,

                  vs.visaInfo.validity_period,
                  vs.entryTypeNames.join(", "),
                  <span className={classes.actionBtn}>
                    {/* <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.visaInfo.id, vs.map_ids)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        localStorage.setItem(
                          "updateVisa",
                          JSON.stringify(vs.visaInfo)
                        );
                        localStorage.setItem(
                          "updateVisatype",
                          vs.entryTypeNames
                        );
                        // localStorage.setItem("entrymapId", vs.);
                        localStorage.setItem("entry_id", vs.entry_ids);
                        navigate("/information_form");
                      }}
                      icon={<FaEdit />}
                    /> */}
                    <MenuButton menuinfo={vs} fetchData={fetchData} />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
