import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CountryTable from "../../components/CountryTable/Index";
import slugify from "react-slugify";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
export default function Fee_type() {
  const navigate = useNavigate();
  const classes = offerStyles();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    fee_name: "",
    fee_icons: "",
    searchText: "",
    fee_descriptions: EditorState.createEmpty(),

    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/feetypes/getallfeetypes",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.Fee_Types);
      // if (actualData.status == 200) {
      //   localStorage.setItem("tkn", actualData.access);
      //   localStorage.setItem(
      //     "details",
      //     JSON.stringify(actualData.admin_user_details)
      //   );
      //   navigate("/dashboard");
      // }
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    if (localStorage.getItem("updateId")) {
      const updateobj = JSON.parse(localStorage.getItem("updateId"));
      // console.log(updateobj.id);
      setState({
        fee_name: updateobj.fee_name,
        fee_icons: updateobj.fee_icons,

        fee_descriptions: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateobj.fee_descriptions))
        ),
        currency: updateobj.currency,
        currency_code: updateobj.currency_code,
        isUpdate: true,
        visaId: updateobj.id,
      });
      // localStorage.removeItem("updateId");
      // handleUpdateVisaCategory(updateobj.id);
    }
  }, []);

  const handleSaveVisaCategory = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        fee_name: state.fee_name,
        fee_icons: state.fee_icons,

        fee_descriptions: JSON.stringify(
          convertToRaw(state.fee_descriptions.getCurrentContent())
        ),
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/feetypes/createfeetypes",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        setState({
          fee_name: "",
          fee_icons: "",

          fee_descriptions: EditorState.createEmpty(),

          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
      }
    } catch (err) {
      console.log(err);
    }
  };
  // /api/country/deletecountry
  const handleVisaCategoryDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/feetypes/deletefeetypes",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateVisaCategory = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        fee_name: state.fee_name,
        fee_icons: state.fee_icons,

        fee_descriptions: JSON.stringify(
          convertToRaw(state.fee_descriptions.getCurrentContent())
        ),

        id: id,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/feetypes/updatefeetypes",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        localStorage.removeItem("updateId");
        setState({
          fee_name: "",
          fee_icons: "",

          fee_descriptions: EditorState.createEmpty(),

          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
        // navigate("/country_list2");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Fee Type
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={6}>
                <CustomInputField
                  label="Fee name"
                  fullWidth
                  value={state.fee_name}
                  onChange={(e) =>
                    setState({
                      ...state,
                      fee_name: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <CustomInputField
                  label="Fee icon"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(
                      storage,
                      `/fee_icons+${Math.random()}`
                    );
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // link = url;
                          //console.log("Image URL:", url);

                          localStorage.setItem("fee_icon", url);
                          console.log("variable value " + state.image);
                          // handleSaveVisaCategory();

                          setState({
                            ...state,
                            fee_icons: localStorage.getItem("fee_icon"),
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.fee_icons && (
                  <>
                    <p>Fee icon</p>{" "}
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.fee_icons}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <p>Description</p>
                <Editor
                  label="Visa Description"
                  editorState={state.fee_descriptions}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // value={state.fee_descriptions}
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      fee_descriptions: e,
                    })
                  }
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.fee_name &&
                        !!state.fee_icons &&
                        !!state.fee_descriptions.getCurrentContent().hasText()
                      ) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleUpdateVisaCategory(state.id);
                    // }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.fee_name &&
                        !!state.fee_icons &&
                        !!state.fee_descriptions.getCurrentContent().hasText()
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Fee types List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CountryTable
              fullWidth
              cols={["Sl No.", "Fee Name", "Fee Icon", "Description", "Action"]}
              rows={[
                ...visaList
                  .filter(
                    (l) =>
                      l.fee_name
                        .toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      JSON.parse(l.fee_descriptions)
                        .blocks[0].text.toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      state.searchText.toLowerCase() === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.fee_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.fee_icons}
                      style={{
                        margin: "auto",
                      }}
                    />,

                    JSON.parse(vs.fee_descriptions).blocks[0].text,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            fee_name: vs.fee_name,
                            fee_icons: vs.fee_icons,
                            fee_descriptions: EditorState.createWithContent(
                              convertFromRaw(JSON.parse(vs.fee_descriptions))
                            ),
                            isUpdate: true,
                            id: vs.id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
            <ToastContainer />
          </Paper>
        </div>
      </div>
    </div>
  );
}
