import React, { useState, useEffect } from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import {
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function LoginForm() {
  const navigate = useNavigate();
  const classes = layoutStyles();
  const [state, setState] = useState({
    uname: "",
    password: "",
    block: "",
    district: "",
    captcha: "",
  });
  const [blocks, setBlocks] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [captchaImage, setCaptchaImage] = useState(
    "https://www.pandasecurity.com/en/mediacenter/src/uploads/2014/09/avoid-captcha.jpg"
  );

  useEffect(() => {
    // Fetch blocks and districts data from your API
    const fetchBlocksAndDistricts = async () => {
      try {
        const blocksResponse = await axios.get(endPoints.blocks);
        const districtsResponse = await axios.get(endPoints.districts);
        setBlocks(blocksResponse.data);
        setDistricts(districtsResponse.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchBlocksAndDistricts();
  }, []);

  useEffect(() => {
    // Fetch CAPTCHA image from your API
    const fetchCaptchaImage = async () => {
      try {
        const captchaResponse = await axios.get(endPoints.captcha);
        setCaptchaImage(captchaResponse.data.imageUrl);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCaptchaImage();
  }, []);

  const login = () => {
    if (
      state.password === "" ||
      // state.block === "" ||
      state.district === "" ||
      state.captcha === ""
    ) {
      toast.error("Please fill in all fields", {
        position: "top-center",
      });
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Paper className={classes.layOutPaper}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <CustomInputField
            label="Username"
            fullWidth
            value={state.uname}
            onChange={(e) =>
              setState({
                ...state,
                uname: e.target.value,
              })
            }
            helperText="Enter your username"
          />
        </Grid> */}

        <Grid item xs={12}>
          <CustomInputAndSelectField
            options={["Khordha", "Ganjam", "Gajapati", "Balangir"]}
            value={state.district}
            changeCallBack={(e, v) => {
              setState({
                ...state,
                district: v,
              });
            }}
            label="District"
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12}>
          <CustomInputAndSelectField
            options={["Rangeilunda", "kukudakhandi", "Digapahandi"]}
            value={state.block}
            changeCallBack={(e, v) => {
              setState({
                ...state,
                block: v,
              });
            }}
            label="Block"
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <img
            src={captchaImage}
            alt="captcha"
            style={{
              display: "block",
              marginBottom: "10px",
              height: "50px",
              width: "150px",
            }}
          />
          <CustomInputField
            label="Captcha"
            fullWidth
            value={state.captcha}
            onChange={(e) =>
              setState({
                ...state,
                captcha: e.target.value,
              })
            }
            helperText="Enter the captcha text"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            text={"Sign In"}
            fullWidth
            variant="contained"
            onClick={login}
          />
        </Grid>
        <ToastContainer />
      </Grid>
    </Paper>
  );
}
