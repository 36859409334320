import { Link as RouterLink } from "react-router-dom";
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../../components/Page";

import MHidden from "../../components/@material-extend/MHidden";
import LoginForm from "./form";
import logo from "../../layouts/logo.png";
// import AuthSocial from "../components/authentication/AuthSocial";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  "& img": {
    margin: "0px auto",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  // color: theme.palette.secondary.lighter,
  color: "black",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
  background: "#F0EEEE",
  "& img": {
    // background: theme.palette.grey[300],
    background: "#F0EEEE",
    borderRadius: theme.shape.borderRadius,
    padding: 20,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 550,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout> */}

      <Container maxWidth="lg">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <img src={logo} alt="login" width={"55%"} />
            {/* <Typography variant="h4" gutterBottom>
              Login
            </Typography> */}
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

          {/* <MHidden width="xsDown">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don't have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Sign Up
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome
          </Typography>
          <img src={logo} alt="login" width={"55%"} />
        </SectionStyle>
      </MHidden> */}
    </RootStyle>
  );
}
