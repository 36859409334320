import { makeStyles } from "@mui/styles";

let DashBoardStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    display: "flex",
    width: "100%",
    height: "inherit",
  },
  contentHead: {
    "& h3": {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
  },
  contentMain: {
    marginTop: 20,
    fontWeight: "bold",
    fontSize: "1.2rem",
    textAlign: "center",
    "& img": {
      margin: "auto",
    },
  },
  icon: {
    height: 24,
    width: 24,
  },
  divider: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "60px auto",
  },
  emptyView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingBottom: "3vh",
  },
  contentSection: {
    height: "fit-content",
    [theme.breakpoints.up("xs")]: {
      padding: "40px 20px",
      width: "100%",
      overflow: "auto",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "40px 40px",
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    },
  },
  stack: {
    width: "-webkit-fill-available",
  },
  cardTitle: {
    padding: "inherit",
    fontWeight: "bold",
  },
  cardSubTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      padding: "5px 20px",
    },
  },
  cardContainer: {
    textAlign: "center",
    display: "flex",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      maxWidth: 320,
      margin: "0px auto",
      padding: "0px 5px",
    },
    overflow: "auto",
    gap: 20,
    height: "inherit",
  },
}));

export default DashBoardStyles;
