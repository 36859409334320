import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import CustomInputAndSelectField from "../CustomInputAndSelectField/Index";

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  boxShadow: "none",
  textAlign: "center",
  margin: theme.spacing(3, 0),
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.secondary.lighter,
}));

export default function CustomDropdownSelector({ date, setDate }) {
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedGP, setSelectedGP] = React.useState("");
  const [selectedDS, setSelectedDS] = React.useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const gps = ["Alavar", "Balikuda", "Kalio", "Machhagaon", "Thailo"];
  const dss = ["Biridi", "Erasama", "Kujanga", "Tirtol"];

  return (
    <RootStyle>
      <b
        style={{
          margin: "auto 10px",
        }}
      >
        Select Month , Block and GP:{" "}
      </b>

      <div
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "space-between",
        }}
      >
        {/* <FormControl fullWidth>
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <CustomInputAndSelectField
          options={months}
          value={selectedMonth}
          changeCallBack={(e, v) => {
            setSelectedMonth(v);
          }}
          label="Month"
          fullWidth
        />
        <CustomInputAndSelectField
          options={dss}
          value={selectedDS}
          changeCallBack={(e, v) => {
            setSelectedGP(v);
          }}
          label="Block"
          fullWidth
        />
        <CustomInputAndSelectField
          options={gps}
          value={selectedGP}
          changeCallBack={(e, v) => {
            setSelectedDS(v);
          }}
          label="GP"
          fullWidth
        />

        <Button
          onClick={() => {
            // setDate({
            //   month: selectedMonth,
            //   gp: selectedGP,
            // });
          }}
          style={{
            marginLeft: "20px",
            width: "400px",
            height: "40px",
            marginTop: "44px",
          }}
          color="primary"
          variant="contained"
        >
          Fetch Data
        </Button>
      </div>
    </RootStyle>
  );
}
