import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FaEllipsisV } from "react-icons/fa";

const CustomMenuButton = ({ menuinfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [status, setStatus] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    setViewOpen(true);
    handleClose();
  };

  const handleUpdateStatusClick = () => {
    setUpdateStatusOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setViewOpen(false);
    setUpdateStatusOpen(false);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdateStatus = () => {
    // Handle the status update logic here
    console.log("Status updated to:", status);
    setUpdateStatusOpen(false);
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleUpdateStatusClick}>Update Status</MenuItem>
      </Menu>

      <Dialog open={viewOpen} onClose={handleDialogClose}>
        <DialogTitle>Applicant Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Applicant Name:</strong> {menuinfo.applicantName}
          </DialogContentText>
          <DialogContentText>
            <strong>Village Name:</strong> {menuinfo.villageName}
          </DialogContentText>
          <DialogContentText>
            <strong>Block Name:</strong> {menuinfo.blockName}
          </DialogContentText>
          <DialogContentText>
            <strong>District:</strong> {menuinfo.district}
          </DialogContentText>
          <DialogContentText>
            <strong>Date:</strong> {menuinfo.date}
          </DialogContentText>
          <DialogContentText>
            <strong>Status:</strong> {menuinfo.status}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateStatusOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="status"
            label="Status"
            type="text"
            fullWidth
            value={status}
            onChange={handleStatusChange}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleUpdateStatus}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomMenuButton;
